<template>
    <div class="main">
        <div v-show="!success">
            <div class="box-replace">
                <div class="box-search rent">
                    <h6 class="title">Patrimônio Atual</h6>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="8" xl="8">
                            <div>
                                <input type="text" name="search" placeholder="Digite o patrimônio atual..."
                                    @keyup.enter.prevent="getFirstSearch()" v-model="firstSearch" />
                                <Loading v-if="loadingRent" type="line" :center="false" />
                            </div>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <div class="div-btn">
                                <Button _key="btnRent" color="white" backGroundColor="#B8860B"
                                    classIcon="fa-solid fa-magnifying-glass" size="small" :clicked="getFirstSearch" />
                                <Button _key="btnCleanRent" color="white" backGroundColor="#B8860B"
                                    classIcon="fa-solid fa-broom" size="small" :clicked="cleanRent" />
                            </div>
                        </b-col>
                    </b-row>
                    <div class="div-legend title" v-if="!disabled">
                        <i class="fas fa-box"></i>
                        Substituir <b>{{ firstData.productFullName }}</b> referente a locação <b> Nº{{
                            firstData.number }} {{
                                firstData.customerFullName }} </b>
                    </div>
                </div>

                <div class="box-search new" v-if="!disabled">
                    <h6 class="title">Novo Patrimônio </h6>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="8" xl="8">
                            <div>
                                <input type="text" name="search" placeholder="Digite o novo patrimônio ..."
                                    @keyup.enter.prevent="getPatrimony()" v-model="secondSearch" />
                                <Loading v-if="loadingReplace" type="line" :center="false" />
                            </div>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <div class="div-btn">
                                <Button _key="btnReplace" :disabled="disabled" color="white" backGroundColor="#00058B"
                                    classIcon="fa-solid fa-magnifying-glass" size="small" :clicked="getPatrimony" />
                                <Button _key="btnCleanReplace" :disabled="disabled" color="white"
                                    backGroundColor="#00058B" classIcon="fa-solid fa-broom" size="small"
                                    :clicked="cleanReplace" />
                            </div>
                        </b-col>
                    </b-row>
                    <div class="div-legend title" v-if="secondData.id">
                        <i class="fas fa-box"></i>
                        Patrimônio Nº <b>{{ secondData.barCode }}</b> referente o produto <b>{{
                            secondData.product.typeProduct.name
                            }} ({{ secondData.product.name }})</b>
                    </div>
                </div>
            </div>
            <div class="text-center" v-if="!disabled">
                <Button _key="btnTransfer" :disabled="!secondData.id" title="Substituir" color="white"
                    backGroundColor="#156EBE" classIcon="fas fa-random" size="large" :clicked="replacePatrimony" />
            </div>
        </div>
        <div v-show="success">
            <div class="div-molded text-center">
                <Molded>
                    <div class="msg-success">Patrimônio substituído com sucesso!</div>
                    <div class="icon-success">
                        <i class="fa-light fa-circle-check"></i>
                    </div>
                    <div>
                        <Button _key="btnBack" type="info" title="Voltar" classIcon="fa-solid fa-circle-arrow-left"
                            size="small" :clicked="back" />
                    </div>
                    <br />
                </Molded>
            </div>
        </div>
    </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapActions, mapMutations, mapState } from "vuex";

export default {
    name: "ReplacementPatrimony",
    props: ["value"],
    components: {
        Button,
        Molded,
        Loading
    },
    data() {
        return {
            urlGetAll: "/api/v1/operational/search",
            urlGetPatrimony: "/api/v1/stock/patrimony/get-by-barcode-replace",
            urlReplace: "/api/v1/stock/patrimony-moviment/replace-patrimony",
            firstSearch: "",
            secondSearch: "",
            firstData: { number: 0 },
            secondData: {},
            loadingRent: false,
            loadingReplace: false,
            success: false,
        };
    },
    mounted() {
        this.removeLoading(["panel"]);
    },
    computed: {
        disabled() {
            if (this.firstData.number > 0) {
                return false;
            } else { return true; }
        }
    },
    methods: {
        ...mapActions("generic", ["getApi", "putApi"]),
        ...mapMutations("generic", ["addNotifications", "removeLoading"]),
        getFirstSearch() {
            this.loadingRent = true;
            let params = {
                url: this.urlGetAll,
                obj: {
                    filter: "patrimonyBarCode",
                    search: this.firstSearch,
                },
            };
            this.getApi(params).then((response) => {
                if (response.content.length > 0) {
                    this.firstData = response.content[0];
                } else {
                    this.addNotifications([{ message: "Patrimônio não encontrado!" }]);
                    this.cleanRent();
                }
                this.removeLoading(["btnRent"]);
                this.loadingRent = false;
            });
        },
        getPatrimony() {
            this.loadingReplace = true;
            let params = {
                url: this.urlGetPatrimony,
                obj: {
                    productId: this.firstData.productId,
                    barCode: this.secondSearch,
                },
            };
            this.getApi(params).then((response) => {
                if (response.success) {
                    this.secondData = response.content;
                } else {
                    this.cleanReplace();
                }
                this.removeLoading(["btnReplace"]);
                this.loadingReplace = false;
            });
        },
        replacePatrimony() {
            let params = {
                url: this.urlReplace,
                obj: {
                    id: this.firstData.patrimonyMovimentId,
                    any: this.secondData.barCode
                }
            };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.success = true;
                }
                this.removeLoading(["btnTransfer"]);
            });
        },
        cleanRent() {
            this.firstSearch = "";
            this.firstData = { number: 0 };
            this.loadingRent = false;
            this.removeLoading(["btnRent", "btnCleanRent"]);
        },
        cleanReplace() {
            this.secondSearch = "";
            this.secondData = {};
            this.loadingReplace = false;
            this.removeLoading(["btnReplace", "btnCleanReplace"]);
        },
        back() {
            this.success = false;
            this.firstSearch = "";
            this.secondSearch = "";
            this.firstData = { number: 0 };
            this.secondData = {};
            this.removeLoading(["btnBack"]);
        }
    },
};
</script>

<style scoped>
.box-replace {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: auto;
    border-radius: 10px;
    padding: 20px;
}


.box-search {
    padding-left: 20px;
    margin: 20px 0;
    position: relative;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 15px;
    transition: transform 0.3s;
    box-shadow: 0px 10px 100px -6px rgb(0 0 0 / 5%);
}

.molded-search {
    border: 1px solid #CED4DA;
    border-radius: 50px;
}


.rent {
    border-left: 2px solid #B8860B;
}

.new {
    border-left: 2px solid #00058B;
}


input {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    margin: 10px;
    border: 1px solid #ced4da;
    border-radius: 50px;
    transition: border-color 0.3s;
}

.div-btn {
    margin-top: 20px;
}

.div-legend {
    margin-left: 15px;
}

.div-molded {
    width: 550px;
    margin: auto;
}

.msg-success {
    font-size: 18px;
}

.icon-success {
    font-size: 55px;
    color: #38a169;
    margin-top: 10px;
    margin-bottom: 15px;
}
</style>